import "bootstrap";
import "./lightbox";
import "./jquery-unveil.js";
import "jquery-lazy";
import "spotlight";

$(function () {
    if ($("#galleryRow").length > 0) {
        initGallery();
    }
});

function initGallery() {
    $("#galleryRow").load("/gallery/images", function () {
        $(".gallery-img").Lazy({
            chainable: false,
            effect: "fadeIn",
            effectTime: 500,
            threshold: 0,
            visibleOnly: true,
            onError: function (element) {
                console.log("error loading " + element.data("src"));
            },

            afterLoad: function (element) {
                element.addClass("loaded");
            },
        });

        document.querySelectorAll(".gallery-img-link").forEach((el) =>
            el.addEventListener("click", (e) => {
                e.preventDefault();
                const lightbox = new Lightbox(el, {
                    keyboard: false,
                    size: "fullscreen",
                });
                lightbox.show();
            })
        );
    });
}
