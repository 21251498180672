/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import "./styles/app.css";
import "./gallery";
import * as bootstrap from "bootstrap";
window.bootstrap = bootstrap;
// start the Stimulus application
import "./bootstrap";
import $ from "jquery";

import "./jqZoom";
import "./cookie-alert";
import "./contact-form";

var dataImgModal;

$("[data-img-modal]").click(function () {
    const imgSrc = $(this).attr("src");
    const imgModal = `
  <div class="modal fade data-img-modal" id="data-img-modal" tabindex="-1" aria-labelledby="data-img-modal" aria-modal="true" role="dialog" style="display: block;">
    <div class="modal-dialog modal-lg modal-dialog-centered data-img-modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="btn-close data-img-modal-close-btn" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <img src="${imgSrc}" class="data-img-modal-img"/>
        </div>
      </div>
    </div>
  </div>
  `;
    $("body").append(imgModal);
    dataImgModal = new bootstrap.Modal(document.getElementById("data-img-modal"), {});
    dataImgModal.show();
});

$(document).on("click", ".data-img-modal-close-btn", function () {
    dataImgModal.hide();
    setTimeout(function () {
        $(".data-img-modal").remove();
    }, 250);
});

$(".express-rifle-main-img").addClass("show");

$(".express-rifle-img").click(function (e) {
    e.preventDefault();

    $(".express-rifle-main-img").removeClass("show");

    $(".express-rifle-img").removeClass("active");
    $(this).addClass("active");

    let id = $(this).attr("id");
    let src = $(this).attr("src");

    let $activeImg = $("#activeImg");

    $activeImg.attr("src", src);

    $(".express-rifle-main-img").attr("data-img-id", id);

    $(".express-rifle-main-img").addClass("show");
});

/*-----
    Gallery
--------------------------------*/
let el = $("#galleryModal")[0];
const galleryModal = new bootstrap.Modal(el, {});

$("#galleryModal .btn-close").click(function () {
    galleryModal.hide();
});

$(".gallery-card").click(function () {
    let $img = $(this).find(".gallery-img");
    let $galleryModalImg = $("#galleryModalImg");

    let src = $img.attr("src");
    let alt = $img.attr("alt");

    $galleryModalImg.attr("src", src);
    $galleryModalImg.attr("alt", alt);

    galleryModal.show();
});

var activeGalleryCategories = [];
var toggleAll = true;
$("[data-gallery-category-toggle]").addClass("active");
showAllElements();

$("[data-gallery-category-toggle]").click(function () {
    if (!toggleAll) {
        toggleAll = true;
        $(this).addClass("active");
        showAllElements();
        loopGalleryElements();
    }
});

$(".gallery-filter-btn").click(function () {
    if (toggleAll) {
        toggleAll = false;
        activeGalleryCategories = [];
        $("[data-gallery-category-toggle]").removeClass("active");
        $("[data-gallery-category-name]").removeClass("active");
    }

    let galleryRowName = $(this).attr("data-gallery-category-name");
    $(this).toggleClass("active");

    activeGalleryCategories.includes(galleryRowName)
        ? (activeGalleryCategories = activeGalleryCategories.filter((v) => v !== galleryRowName))
        : activeGalleryCategories.push(galleryRowName);

    loopGalleryElements();

    if (!activeGalleryCategories.length) {
        $("[data-gallery-category-toggle]").click();
    }
});

function loopGalleryElements() {
    let elements = $("[data-gallery-row]");

    for (var element of elements) {
        element = $(element);
        let name = element.attr("data-gallery-row");
        if (activeGalleryCategories.includes(name)) {
            element.removeClass("d-none");
        } else {
            element.addClass("d-none");
        }
    }
}

function showAllElements() {
    $("[data-gallery-category-name]").each(function () {
        $(this).addClass("active");
        activeGalleryCategories.push($(this).attr("data-gallery-category-name"));
    });
}
