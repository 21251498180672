(function () {
    "use strict";

    // Fetch all the forms we want to apply custom Bootstrap validation styles to
    var forms = document.querySelectorAll("#contact-form");

    // Loop over them and prevent submission
    Array.prototype.slice.call(forms).forEach(function (form) {
        form.addEventListener(
            "submit",
            function (event) {
                if (!form.checkValidity()) {
                    event.preventDefault();
                    event.stopPropagation();

                    console.log("Form is not valid");
                } else {
                    event.preventDefault();
                    onFormSubmit(event);
                }

                form.classList.add("was-validated");
            },
            false
        );
    });
})();

function onFormSubmit(e) {
    $.ajax({
        url: "/contact-form",
        type: "POST",
        data: $("#contact-form").serialize(),
        success: function (data) {
            $("#contact-form-alert").html(
                `<div class="alert alert-dismissible mb-0 mt-3 alert-${data.variant}" role="alert">${data.message}</div>`
            );

            $("#contact-form").remove();
        },
        error: function (data) {
            console.log(data);
        },
    });
}
